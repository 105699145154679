import Lottie from 'lottie-react';
import { lazy, type LazyExoticComponent, Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';

import AppLoader from '~assets/loader.json';
import { ROUTES } from '~constants/index';
import { useLocalStorage } from '~hooks/index';
import { privateRouteOptions } from '~utils/index';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';

type Pages = Record<string, LazyExoticComponent<() => JSX.Element>>;

const Routes = () => {
  const { sidebarData } = useLocalStorage();

  const LandingPage = lazy(
    () => import('~pages/Public/LandingPage/LandingPage')
  );
  const LoginPage = lazy(() => import('~pages/Public/LoginPage/LoginPage'));
  const UserAuthenticated = lazy(
    () => import('~layouts/UserAuthenticated/UserAuthenticated')
  );
  const Error404 = lazy(() => import('~pages/Error404/Error404'));
  const Home = lazy(() => import('~pages/Private/Home/Home'));
  const Users = lazy(() => import('~pages/Private/Users/Users'));
  const Survey = lazy(() => import('~pages/Private/Survey/Survey'));
  const SchedulingManagement = lazy(
    () => import('~pages/Private/SchedulingManagement/SchedulingManagement')
  );

  const pageSelected = (name: string) => {
    const pages: Pages = {
      users_lists: Users,
      ufca_mama: Survey,
      scheduling_management: SchedulingManagement
    };
    const Page = pages[name];
    return <Page />;
  };

  const router = createBrowserRouter([
    {
      path: '*',
      element: <Navigate to={ROUTES.ERROR} />
    },
    {
      path: ROUTES.ERROR,
      element: <Error404 />
    },
    {
      path: ROUTES.ROOT,
      element: (
        <PublicRoute>
          <LandingPage />
        </PublicRoute>
      )
    },
    {
      path: ROUTES.LOGIN,
      element: (
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
      )
    },
    {
      path: ROUTES.PLATFORM,
      element: <UserAuthenticated />,
      children: [
        {
          path: ROUTES.PLATFORM,
          element: (
            <PrivateRoute module={{ title: 'routes.home' }}>
              <Home />
            </PrivateRoute>
          )
        },
        ...privateRouteOptions(sidebarData).map((item) => {
          return {
            path: `/platform/${item.functional_name}`,
            element: (
              <PrivateRoute module={{ title: item.label }}>
                {pageSelected(item.functional_name!)}
              </PrivateRoute>
            )
          };
        })
      ]
    }
  ]);

  return (
    <Suspense
      fallback={
        <div className="loadingContainer">
          <Lottie animationData={AppLoader} loop={true} />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
